import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import reglamentoEvaluacion from '../../docs/reglamentos/ReglamentoEvaluacionYPromocion2024.pdf'
import reglamentoConvivenciaEscolar from '../../docs/convivencia-escolar/ReglamentoDeConvivencia2024.pdf'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

export const Reglamentos = () => {
    const data = [
        {id:'1', name:'Resumen reglamento de evaluacion', icon:faDownload, ref:reglamentoEvaluacion },
        {id:'2', name:'Nuevo Reglamento de convivencia escolar', icon:faDownload, ref:reglamentoConvivenciaEscolar },

    ]
    return (
        <Row>
            <Col md={12} className="mb-2">
                <h2 >Reglamentos</h2>
            </Col>
            {data && data.map(e => (
                <Col md={4} key={e.id}>
                    <Card className="bg-dark">
                        <Card.Body className="text-center">
                            <h5 >{e.name}</h5>
                            <a href={ e.ref } target="_blank" rel="noreferrer" className="btn btn-primary btn-outline btn-block">
                                <span ><FontAwesomeIcon icon={e.icon} /> Ver Completo</span>
                            </a>
                        </Card.Body>
                    </Card>
                </Col>
            ))}

        </Row>
    )
}
