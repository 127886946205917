import React from 'react'

export const ModalidadEstudios = () => {
    return (
        <>
            <h2 className="has-text-white">Modalidad de estudio</h2>
            <ul>
                <li>Educación general básica de 1° a 8°.</li>
                {/* <li>Atención personalizada: Max ? estudiantes por sala</li> */}
                <li>Jornada completa.</li>
                <li>Horario de clases Lunes a Jueves 8:00 a 15:30 y Viernes de 8:00 a 13:00.</li>
            </ul>
        </>
    )
}
